import React from 'react';
import './footer.scss';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll, animateScroll as scroll } from 'react-scroll';
import hookLogo from '../../assets/images/blue-logo.png';

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <section className='footer-container'>
            <div className='footer-wrapper'>
                <div className='footer-items'>
                    <div className='footer-items-title'>
                        <h4>Hooklab</h4>
                    </div>
                    <div className='footer-items-links'>
                        <LinkRouter 
                            to='/' 
                            smooth={true} 
                            spy={true} 
                            exact='true' 
                            offset={-80}
                        >
                            Sobre
                        </LinkRouter>
                        <a href='https://hooklab.com.br/blog/' target='_blank'>Blog</a>
                        <LinkRouter to='/planos'>Planos</LinkRouter>
                        <LinkRouter to='/demonstracao'>Demonstração gratuita</LinkRouter>
                        <LinkRouter to='/privacidade'>Política de Privacidade</LinkRouter>
                    </div>
                </div>
                <div className='footer-items'>
                    <div className='footer-items-title'>
                        <h4>Produtos</h4>
                    </div>
                    <div className='footer-items-links'>
                        <LinkRouter to='/solucoes/monitoramento-de-concorrentes'>Monitoramento de Concorrentes</LinkRouter>
                        <LinkRouter to='/solucoes/monitoramento-de-revendedores'>Monitoramento de Revendedores</LinkRouter>
                        <LinkRouter to='/solucoes/monitoramento-netshoes'>Monitoramento Netshoes</LinkRouter>
                        <LinkRouter to='/solucoes/precificacao-dinamica'>Precificação Dinâmica</LinkRouter>
                        <LinkRouter to='/solucoes/solucoes-personalizadas'>Soluções Personalizadas</LinkRouter>
                    </div>
                </div>
                <div className='footer-items'>
                    <div className='footer-items-title'>
                        <h4>Redes sociais</h4>
                    </div>
                    <div className='footer-items-links'>
                        <a href='https://www.linkedin.com/company/hooklab/' target='_blank'>Linkedin</a>
                        <a href='https://www.facebook.com/hooklabonline' target='_blank'>Facebook</a>
                    </div>
                </div>
                <div className='footer-info'>
                    <div className='footer-address'>
                        <div className='footer-items-title'>
                            <h4>Endereço</h4>
                        </div>
                        <ul>
                            <li>R. Lauro Linhares</li>
                            <li>Nº 2055 - sala 701</li>
                            <li>Trindade, Florianópolis - SC</li>
                            <li>CEP: 88036-003</li>
                        </ul>
                        <div className='footer-items-title'>
                            <h4>CNPJ</h4>
                        </div>
                        <div className='footer-cnpj'>
                            34.871.402/0001-83
                        </div>
                    </div>

                </div>
                <div>
                    <div className='footer-contact'>
                        <div className='footer-items-title'>
                            <h4>Contato</h4>
                        </div>
                        <ul>
                            <li>+55 48 9 9834-4663</li>
                            <li>gustavo.prade@hooklab.com.br</li>
                        </ul>
                        <div>
                            <div className='footer-items-title'>
                                <h4>Horário de Atendimento</h4>
                            </div>
                            <div>Segunda a sexta-feira das 09h às 18h</div>
                        </div>
                    </div>
                    {/* <div>
                        <div className='footer-items-title'>
                            <h4>CNPJ</h4>
                        </div>
                        <div className='footer-cnpj'>
                            34.871.402/0001-83
                        </div>
                    </div> */}
                </div>

            </div>


            <div className='footer-bottom'>
                <div className='footer-logo'>
                    <LinkRouter to='/' onClick={toggleHome}><img src={hookLogo} /><span>hook</span><span className='blue'>lab</span></LinkRouter>
                </div>
                <div className='footer-copyright'>
                    Hooklab © {new Date().getFullYear()} Todos os direitos reservados.
                </div>
                <div className='footer-icons'>
                    <a href='https://www.linkedin.com/company/hooklab/' target='_blank'><i className='fab fa-linkedin' /></a>
                    <a href='https://www.facebook.com/hooklabonline' target='_blank'><i className='fab fa-facebook' /></a>
                </div>
            </div>
        </section>
    )
}

export default Footer;
