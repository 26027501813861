import React from 'react';
import useForm from './useForm';
import validateInfo from './validateInfo';
import './PlansForm.scss';

const FormBefore = ({ submitForm }) => {

    const { handleChange, values, handleSubmit, errors } = useForm(submitForm, validateInfo);

    return (
        <div className='plans-form-wrapper'>
            <div className='plans-form-heading'>
                <h3>Deseja saber mais sobre algum dos nossos planos?</h3>
            </div>
            <div className='plans-form-subheading'>
                <p>
                    Preencha o formulário, e um de nossos especialistas entrará em contato com você.
                </p>
            </div>
            <form name='plans-form' id='plans-form' className='plans-form' onSubmit={handleSubmit}>
                <div className='plans-form-inputs-area'>
                    <div className='plans-form-inputs'>
                        <label htmlFor='solution' className='form-label'>Solução:</label>
                        <select name='solution' id='solution' className='plans-form-input'>
                            <option value='price-monitoring'>Monitoramento de Concorrentes</option>
                            <option value='reseller-monitoring'>Monitoramento de Revendedores</option>
                            <option value='dynamic-pricing'>Precificação Dinâmica</option>
                            <option value='netshoes-monitoring'>Monitoramento Netshoes</option>
                        </select>
                        {errors.message && <p>{errors.message}</p>}
                    </div>
                    <div className='plans-form-inputs'>
                        <label htmlFor='username' className='form-label'>Nome:</label>
                        <input type='text' id='username' name='username' className='plans-form-input' placeholder='Seu nome'
                            value={values.username} onChange={handleChange} />
                        {errors.username && <p>{errors.username}</p>}
                    </div>
                    <div className='plans-form-inputs'>
                        <label htmlFor='email' className='form-label'>Email:</label>
                        <input type='email' id='email' name='email' className='plans-form-input' placeholder='Seu e-mail'
                            value={values.email} onChange={handleChange} />
                        {errors.email && <p>{errors.email}</p>}
                    </div>
                    <div className='plans-form-inputs'>
                        <label htmlFor='phone' className='form-label'>Telefone:</label>
                        <input type='tel' id='phone' name='phone' className='plans-form-input' placeholder='Seu telefone'
                            value={values.phone} onChange={handleChange} />
                        {errors.phone && <p>{errors.phone}</p>}
                    </div>
                    <div className='plans-form-inputs'>
                        <label htmlFor='company' className='form-label'>Empresa:</label>
                        <input type='text' id='company' name='company' className='plans-form-input' placeholder='Sua Empresa'
                            value={values.company} onChange={handleChange} />
                        {errors.company && <p>{errors.company}</p>}
                    </div>
                    <div className='plans-form-inputs'>
                        <label htmlFor='position' className='form-label'>Cargo:</label>
                        <input type='text' id='position' name='position' className='plans-form-input' placeholder='Seu Cargo'
                            value={values.position} onChange={handleChange} />
                        {errors.position && <p>{errors.position}</p>}
                    </div>
                    <div className='form-inputs'>
                        <label htmlFor='origin' className='form-label'>Como você conheceu a Hooklab?</label>
                        <select onChange={handleChange} value={values.origin} id='origin' name='origin' className='required plans-form-input' >
                            <option value='select'>Selecione uma opção</option>
                            <option value='email'>E-mail promocional</option>
                            <option value='google'>Google</option>
                            <option value='indicacao'>Indicação</option>
                            <option value='parceiros'>Parceiros da Hooklab</option>
                            <option value='redes-sociais'>Redes Sociais</option>
                            <option value='blog'>Blog</option>
                            <option value='outros'>Outros</option>
                        </select>
                        {errors.origin && <p>{errors.origin}</p>}
                    </div>
                    {values.origin === 'outros' &&
                        <div className='form-inputs'>
                            <input type='text' id='outros_origin' name='outros_origin' className='required plans-form-input' placeholder='Como você conheceu a Hooklab?'
                                value={values.outros_origin} onChange={handleChange} />
                            {errors.outros_origin && <p>{errors.outros_origin}</p>}
                        </div>}
                    <div className='form-inputs'>
                        <div className='accept-privacy-policy'>
                            <input value={values.privacy} onClick={handleChange} type='checkbox' name='privacy' id='privacy' />
                            <span>Estou de acordo com a <a href="/privacidade" target="_blank">Política de Privacidade</a> da Hooklab</span>
                        </div>
                        {errors.privacy && <p>{errors.privacy}</p>}
                    </div>
                    <button className='btn btn--form-input' type='submit'>Fale com um de nossos consultores</button>
                </div>
            </form>
        </div>
    )
}

export default FormBefore;
