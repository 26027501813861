import React, { useState } from 'react';
import FormBefore from './FormBefore';
import './PlansForm.scss';
import FormSuccess from './FormSuccess';

const PlansForm = () => {

    const [isSubmitted, setIsSubmitted] = useState(false);

    function submitForm() {
        setIsSubmitted(true);
    }
    
    return (
        <section className='plans-form-container' id='plans-form'>
            {!isSubmitted ? (<FormBefore submitForm={submitForm} />) : (<FormSuccess />)} 
        </section>
    )
}

export default PlansForm;