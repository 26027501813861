import React from 'react';
import { posts } from './blogData';
import './recentposts.scss';

export default function RecentPosts() {

    const latestPosts = posts.reverse().slice(0, 3);    

    return (
        <div className='latest-posts-container'>
            {latestPosts.map(recentPost => 
                <div className='latest-posts-wrapper' key={recentPost.id}>
                    <a className='post-link' href={recentPost.link} target='_blank'>
                        <img className='post-img' src={recentPost.image} />
                        <div className='post-title'><h3>{recentPost.title}</h3></div>
                        <p className='post-description'>{recentPost.description.substring(0,150)}...</p>
                    </a>
                </div>
            )}
        </div>
  )
  
};

