export default function validateInfo(values) {
    let errors = {}

    if(!values.username.trim()) {
        errors.username = 'Por favor, preencha este campo!'
    }

    if(!values.email) {
        errors.email = 'Por favor, preencha este campo!'
    } else if(!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Este não é um endereço de e-mail válido.'
    }

    if(!values.phone) {
        errors.phone = 'Por favor, preencha este campo!'
    } else if(!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(values.phone)) {
        errors.phone = 'Este não é um número de telefone válido.'
    }

    if(!values.company) {
        errors.company = 'Por favor, preencha este campo!'
    }

    if(!values.position) {
        errors.position = 'Por favor, preencha este campo!'
    }

    if (!values.origin || values.origin === 'select') {
        errors.origin = 'Selecione uma opção'
    }

    if (values.origin === 'outros' && !values.outros_origin) {
        errors.outros_origin = 'Por favor, preencha este campo!'
    }

    if (!values.privacy) {
        errors.privacy = 'Você precisa concordar com a política de privacidade para continuar'
    }


    return errors;
}