import { Link as LinkScroll } from 'react-scroll';
import "./GuidedPlans.scss";


export default function GuidadePlans() {

    return (
        <section className='plans-main-container'>
            <div className='plans-main-wrapper'>
                <div className='plans-main-heading'>
                    <h3>Escolha o plano ideal para sua operação!</h3>
                </div>
                <div className='plans-main-subheading'>
                    <p>
                        Na Hooklab, os planos são personalizados de acordo com as necessidades do seu negócio.
                        Definimos a mensalidade com base em quatro fatores principais:
                    </p>
                </div>
                <div className='plans-main-grid'>
                    <div className='plans-card'>
                        <div className='plans-card-content'>
                            <div className='topic plans-card-title'>
                                <h4>Número de sites monitorados</h4>
                            </div>
                            <div className='topic'>
                                <div className='card-topic-heading'>
                                    <p>Monitoramos diferentes marketplaces e e-commerces no Brasil e Exterior.</p>
                                </div>
                            </div>

                            {/*
                            essa seção em seguida foi comentada pq não sei se faz muito sentido...
                            precisamos discutir 
                        */}

                            {/* <div className='plans-card-topics'>
                                <div className='card-topic-heading'>
                                    <h5>Precificação Básica:</h5>
                                </div>
                                <ul className='plans-card-features'>
                                    <li>Ferramentas Básicas de Precificação (Preço de Custo, Margens, Comissão e Frete).</li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div className='plans-card'>
                        <div className='plans-card-content'>
                            <div className='topic plans-card-title'>
                                <h4>Quantidade de SKUs</h4>
                            </div>
                            <div className='topic'>
                                <div className='card-topic-heading'>
                                    <p>Você pode escolher monitorar todo o seu catálogo de produtos ou apenas uma parte deles.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='plans-card'>
                        <div className='plans-card-content'>
                            <div className='topic plans-card-title'>
                                <h4>Frequência de atualização</h4>
                            </div>
                            <div className='topic'>
                                <div className='card-topic-heading'>
                                    <p>
                                        Escolha a periodicidade ideal para sua necessidade.
                                        Com frequências de 1 vez ao dia até 24 vezes ao dia.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='plans-card'>
                        <div className='plans-card-content'>
                            <div className='topic plans-card-title'>
                                <h4>Monitoramento de frete</h4>
                            </div>
                            <div className='topic'>
                                <div className='card-topic-heading'>
                                    <p>
                                        Se deseja analisar o preço e prazo de entrega do frete de seus concorrentes,
                                        essa opção pode ser ativada.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='plans-card-button'>
                    <LinkScroll to='plans-form' smooth={true} spy={true} exact='true' offset={-80}>
                        <button>Fale com um de nossos consultores</button>
                    </LinkScroll>
                </div>
            </div>
        </section>
    )
}